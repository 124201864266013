<template>
  <div class="page-container">
    <XeAnimationSubmit
      action-text="Creating your account"
      bottom-text="This might take up to 20 seconds"
      :animation="confirmation"
      :dots="dots"
    />
  </div>
</template>

<script>
import { ref, reactive, onMounted, onBeforeUnmount } from '@vue/composition-api'
import { usePromiseLazy } from 'vue-composable'
import XeAnimationSubmit from '@/components/XeAnimationSubmit/XeAnimationSubmit'
import confirmation from '@/assets/animations/confirmation.json'

import { useAnalyticsStore } from '@/stores/analytics'
import { useDashboardStore } from '@/stores/dashboard'
import { useAuthStore } from '../../stores/auth'
import { useCompanyDetailsStore } from '@/stores/companyDetails'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { useAppStore } from '@/stores/app'
import { useRegistrationStore } from '@/stores/registration'

export default {
  name: 'RegistrationSubmit',
  components: {
    XeAnimationSubmit,
  },
  setup() {
    const analyticsStore = useAnalyticsStore()
    const dashboardStore = useDashboardStore()
    const authStore = useAuthStore()
    const companyDetailsStore = useCompanyDetailsStore()
    const corporateRegistrationStore = useCorporateRegistrationStore()
    const appStore = useAppStore()
    const registrationStore = useRegistrationStore()

    const dots = ref('')
    const accountCreatedSuccess = ref(false)
    const accountAlreadyRegistered = ref(false)
    const companyType = companyDetailsStore.companyType
    const selectedCountry = companyDetailsStore.companyCountry
    const createAccount = reactive(
      usePromiseLazy(() => corporateRegistrationStore.submitRegistration())
    )

    registrationStore.isSubmitRegistration = true
    corporateRegistrationStore.activeStepIdx = 0

    const timer = setInterval(() => {
      if (dots.value.length <= 2) {
        dots.value += '.'
      } else {
        dots.value = ''
      }
    }, 1000)

    onBeforeUnmount(() => {
      clearInterval(timer)
    })

    onMounted(async () => {
      accountCreatedSuccess.value = false
      try {
        await createAccount.exec()
        if (!createAccount.error) {
          if (createAccount.result && createAccount.result.success) {
            const clientNumber = corporateRegistrationStore.clientNumber
            await corporateRegistrationStore.updateMarketingPreferences()

            // Send GTM variable - Registration_Email
            analyticsStore.gtmTrack({
              event: 'Registration_ClientNumber',
              variables: {
                Registration_ClientNumber: clientNumber,
              },
            })
            // Send GTM event - Event_Registration_Complete
            analyticsStore.gtmTrack({
              event: 'Event_Registration_Complete',
              variables: {},
            })
            // Send data to Amplitude/Segment - Event triggered on Thank You Page load
            analyticsStore.identify({
              userId: authStore.profileId,
              traits: {
                referenceSource: 'XEMT Business',
                email: authStore.lastLogin,
                clientNumber,
              },
            })
            analyticsStore.track({
              event: 'Profile Created',
              traits: {
                referenceSource: 'XEMT Business',
                release: appStore.version,
                clientNumber,
              },
            })

            // Allowed company types
            // US: Corporation, Limited Liability Company, Sole Proprietorship
            // CA: Private, Sole Proprietor
            // UK: Limited, Sole
            // APAC ('AU', 'NZ', 'NF', 'CK'): Limited or Sole
            let regionCompanyTypesMapping = new Map(
              dashboardStore.pendingActionsConfig.allowedForPendingActionsById
            )
            const isAllowedForPendingActionsOrStp =
              registrationStore.getCountryOrRegionIsAllowedForStp ||
              regionCompanyTypesMapping.get(selectedCountry)?.includes(companyType)

            corporateRegistrationStore.resetForm()
            registrationStore.resetForm()
            dashboardStore.setClearSessionStore(false)

            // Dashboard redirect
            if (selectedCountry === 'MY') {
              createAccount.loading = true
              window.removeEventListener('beforeunload', window.handleWindowClose)
              window.location = 'https://help.xe.com/hc/en-gb/articles/4408594616721' // error placeholder page for my customers
            } else if (isAllowedForPendingActionsOrStp) {
              // TODO: Check best condition to handle the company type (use company type id or name?)
              window.removeEventListener('beforeunload', window.handleWindowClose)
              dashboardStore.isFirstLoad = true
              registrationStore.registrationCompleted = true
              window.location = corporateRegistrationStore.getRedirectUrl
            } else {
              accountCreatedSuccess.value = true
            }
          } else {
            createAccount.error = true
          }
        }
        // Error checker for 400 error
        if (createAccount?.error?.response?.status === 400) {
          appStore.logException({
            text: 'Exception during registration creation',
            exception: createAccount.error.message,
          })
          appStore.messageBoxGenericError()
          return
        }
      } catch (ex) {
        if (ex.errorCode === 'alreadyRegistered') {
          accountAlreadyRegistered.value = true
        } else {
          createAccount.error = true
        }
        appStore.logException({
          text: 'Exception during registration creation',
          exception: ex,
        })
        appStore.messageBoxGenericError()
      }
    })
    return {
      confirmation,
      dots,
    }
  },
}
</script>

<style lang="postcss">
.page-container {
  @apply flex absolute w-full;
  height: 85%;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
}
</style>
