<template>
  <div class="submit-animation-container">
    <div class="flex-row">
      <!-- eslint-disable-next-line vue/component-name-in-template-casing -->
      <lottie-vue-player
        :src="animationParsed"
        :autoplay="true"
        :loop="true"
        style="width: 100px; background-color: transparent"
      ></lottie-vue-player>
    </div>
    <Transition name="slide-fade" mode="out-in">
      <div :key="actionText" class="flex-row action-container">
        <p class="action-text">
          {{ actionText }}
        </p>

        <span class="dots">{{ dots }}</span>
      </div>
    </Transition>
    <div class="flex-row">
      <p class="bottom-text">{{ bottomText }}</p>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import radialLoader from '@/assets/animations/radialLoader.json'

export default {
  props: {
    actionText: {
      type: String,
      required: true,
    },
    bottomText: {
      type: String,
      required: true,
    },
    animation: {
      type: Object,
      required: false,
      default: radialLoader,
    },
    dots: {
      type: String,
      required: false,
      default: '...',
    },
  },
  setup(props) {
    const animationParsed = computed(() => {
      return JSON.stringify(props.animation)
    })

    return {
      animationParsed,
    }
  },
}
</script>

<style lang="postcss" scoped>
.submit-animation-container {
  @apply flex flex-col items-center min-w-max-content max-w-min-content max-h-48;
}

.action-container {
  @apply pt-6;
  display: inline-block;
  .dots {
    @apply absolute text-xl font-semibold leading-8 pb-4 inline w-0;
  }
  .action-text {
    @apply text-xl font-semibold leading-8 pb-4 inline;
    font-family: 'Inter';
    color: var(--neutral-grey-dark-900, #333);
    @screen sm {
      @apply text-2xl pb-1;
    }
  }
}

.bottom-text {
  @apply text-lg font-normal leading-6 w-max-content;
  font-family: 'Inter';
  color: #5c667b;
}

.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.bottom-text {
  @apply text-lg font-normal leading-6 w-max-content;
  font-family: 'Inter';
  color: #5c667b;
}
</style>
